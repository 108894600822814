import React from 'react'

const cards = [
  {
    title: 'Friday, 31th of December 2021, 10:00 UTC',
    text: (
      <p>
        Presale - We are officially the first multi-chain NFT project launched
        in 2022
      </p>
    ),
    isQuartal: false,
  },
  {
    title: 'Sunday, 3rd of April 2022, 17:00 UTC',
    text: <p>Public sale</p>,
    isQuartal: false,
  },
  {
    title: 'Q1 2022',
    text: (
      <p>
        22% of all income will be devolved to selected nonprofits, starting with
        Cure Rare Disease.
      </p>
    ),
    isQuartal: true,
  },
  {
    title: 'Sold 11%',
    text: <p>We will airdrop randomly 22 NFT to some lucky holders.</p>,
    isQuartal: false,
  },
  {
    title: 'Sold 33%',
    text: (
      <p>We will pick 33 random 22% NFT owner and ship them their print!</p>
    ),
    isQuartal: false,
  },
  {
    title: 'Sold 66%',
    text: <p>Start working on the breeding collection.</p>,
    isQuartal: false,
  },
  {
    title: 'Sold 88%',
    text: (
      <p>
        10.22% of all primary sale will be allocated to our 22% Fund. Let&apos;s
        start collect cryptoart!
      </p>
    ),
    isQuartal: false,
  },
  {
    title: 'SOLD OUT',
    text: (
      <p>
        We will randomly select 22 owners and will send them a “Tokenframe” to
        display their NFT.
      </p>
    ),
    isQuartal: false,
  },
  {
    title: 'Q2 2022',
    text: (
      <p>
        24.5% of all income will be devolved to selected nonprofits. Each
        quartal the amount of donations will be increased by 2.5%.
      </p>
    ),
    isQuartal: true,
  },
  {
    title: 'Q1/Q2 2022',
    text: (
      <p>Work on partnerships with organisations for disease prevention.</p>
    ),
    isQuartal: true,
  },
]

export default cards
