/* eslint-disable quotes */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const team = [
  {
    name: 'Raffaele Bischof',
    function: 'Coordinator',
    text: 'Passionate about making a difference by thinking out of the box, loving my neighbors, and using my health science background.',
    linkedin: {
      text: 'LinkedIn',
      url: 'https://www.linkedin.com/in/raffaele-bischof',
    },
    instagram: {
      text: 'Instagram',
      url: 'https://www.instagram.com/raffaelebischof',
    },
    image: (
      <StaticImage
        className="image"
        src="../images/team/raffaele.png"
        alt="Raffaele Bischof"
        width={150}
        height={150}
        quality={100}
      />
    ),
  },
  {
    name: 'Damiano Keller',
    function: 'Tech & Marketing',
    text: 'Tech enthusiast and drone passionate. Likes to support pioneering ideas. Strong believer of the crypto space.',
    linkedin: {
      text: 'LinkedIn',
      url: 'https://www.linkedin.com/in/damiano-keller-56363214a/',
    },
    instagram: {
      text: 'Instagram',
      url: 'https://www.instagram.com/sans_damian/',
    },
    image: (
      <StaticImage
        className="image"
        src="../images/team/damiano.png"
        alt="Damiano Keller"
        width={150}
        height={150}
        quality={100}
      />
    ),
  },
  {
    name: 'Jesse Gobbi',
    function: 'Artist',
    text: "Studied both digital and traditional art. I grew up watching Once Upon a Time...Life and sometimes i still do but i feel guilty about it cause i'm 28 years old.",
    linkedin: {
      text: 'LinkedIn',
      url: 'https://www.linkedin.com/in/jesse-gobbi-3a3047227/',
    },
    instagram: {
      text: 'Instagram',
      url: 'https://www.instagram.com/paul_chicken/',
    },
    image: (
      <StaticImage
        className="image"
        src="../images/team/jesse.png"
        alt="Jesse"
        width={150}
        height={150}
        quality={100}
      />
    ),
  },
  {
    name: 'Samuele Bischof',
    function: 'Developer',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit - you guessed right, I am the developer.',
    image: (
      <StaticImage
        className="image"
        src="../images/team/samuele.png"
        alt="Samuele Bischof"
        width={150}
        height={150}
        quality={100}
      />
    ),
  },
  {
    name: 'Jean-Claude Cereghetti',
    function: 'Head of marketing',
    // eslint-disable-next-line quotes
    text: "Always ready to go the extra mile whether it's in the mountains or at work, I bring positivity and creativity to those around me.",
    linkedin: {
      text: 'LinkedIn',
      url: 'https://www.linkedin.com/in/jean-claude-cereghetti-570216199/',
    },
    instagram: {
      text: 'Instagram',
      url: 'https://www.instagram.com/ickjenk/',
    },
    image: (
      <StaticImage
        className="image"
        src="../images/team/jeanclaude.png"
        alt="Jean-Claude"
        width={150}
        height={150}
        quality={100}
      />
    ),
  },
  {
    name: 'Davide Zaramella',
    function: 'Tech Consultant',
    text: 'Blockchain expert. I care about the future by focusing on the present. Lifting weights and empowering people since 1995.',
    linkedin: {
      text: 'LinkedIn',
      url: 'https://www.linkedin.com/in/davide-zaramella/',
    },
    image: (
      <StaticImage
        className="image"
        src="../images/team/davide.png"
        alt="Davide Zaramella"
        width={150}
        height={150}
        quality={100}
      />
    ),
  },
]

export default team
