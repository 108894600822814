import classNames from 'classnames'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './faq.scss'

const Faq = ({ faq }) => {
  const [open, setOpen] = useState(false)
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setOpen(!open)
    }
  }
  return (
    <div
      className={classNames('faq__element', !open && 'faq__element--collapsed')}
    >
      <div
        className="faq__element__q"
        tabIndex={0}
        role="button"
        onClick={() => setOpen(!open)}
        onKeyDown={handleKeyDown}
      >
        <span>{faq.question}</span>
        <div className="faq__element__q__icon">+</div>
      </div>
      {faq.answer && (
        <div className="faq__element__a">
          <div className="faq__element__a__inner">{faq.answer}</div>
        </div>
      )}
    </div>
  )
}

Faq.propTypes = {
  faq: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.node.isRequired,
  }).isRequired,
}

const Faqs = ({ faqs }) => (
  <div className="faq">
    {faqs.map((faq) => (
      <Faq key={faq.question} faq={faq} />
    ))}
  </div>
)

Faqs.propTypes = {
  faqs: PropTypes.arrayOf(Faq.propTypes.faq).isRequired,
}

export default Faqs
