import React from 'react'
import PropTypes from 'prop-types'
import './team.scss'
import Linkedin from '../../assets/linkedin.svg'
import Instagram from '../../assets/instagram.svg'

const TeamMember = ({ member }) => (
  <div className="team-member">
    {member.image}
    <span className="name">{member.name}</span>
    <span className="function">{member.function}</span>

    <span className="text">{member.text}</span>
    <div className="links">
      {member.linkedin && (
        <a
          className="linkedin"
          rel="nofollow noopener noreferrer"
          href={member.linkedin.url}
          target="_blank"
        >
          <Linkedin alt="LinkedIn" />
          {/* {member.linkedin.text} */}
        </a>
      )}
      {member.instagram && (
        <a
          className="instagram"
          rel="nofollow noopener noreferrer"
          href={member.instagram.url}
          target="_blank"
        >
          <Instagram alt="Instragram" />
          {/* {member.instagram.text} */}
        </a>
      )}
    </div>
  </div>
)

TeamMember.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string.isRequired,
    function: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
    linkedin: PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    instagram: PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

const Team = ({ team }) => (
  <div className="team row">
    {team.map((el) => (
      <TeamMember key={el.id} member={el} />
    ))}
  </div>
)

Team.propTypes = {
  team: PropTypes.arrayOf(TeamMember.propTypes.member).isRequired,
}

export default Team
