import React from 'react'

const faqs = [
  {
    question: 'Good to know',
    answer: (
      <>
        <p>
          <b>PRESALE DATE &ndash;</b> Friday, 31<sup>th</sup> of December 2021,
          10:00 UTC
        </p>
        <p>
          <b>PUBLIC SALE DATE &ndash;</b> Sunday, 3<sup>rd</sup> of April 2022,
          17:00 UTC
        </p>
        <p>
          <b>TOTAL NUMBER OF TOKENS &ndash;</b> 10&apos;000
        </p>
        <p>
          <b>TOKENS ON ETHEREUM &ndash;</b> 5&apos;000
        </p>
        <p>
          <b>TOKENS ON SOLANA &ndash;</b> 5&apos;000
        </p>
        <p>
          <b>TOKEN WITHHELD FROM SALE &ndash;</b> 220 (giveaways, marketing and
          team)
        </p>
        <p>
          <b>PRICE PER TOKEN &ndash;</b> TBD
        </p>
        <p>
          <b>CHARITY &ndash;</b> 22% of each sale income and royalties,
          increasing 2.5% each quartal
        </p>
        <p>
          <b>NONPROFITS INVOLVED</b>
          <ul>
            <li>
              <a
                className="link"
                rel="nofollow noopener noreferrer"
                target="_blank"
                href="https://www.cureraredisease.org/"
              >
                Cure Rare Disease
              </a>
            </li>
            <li>
              <a
                className="link"
                rel="nofollow noopener noreferrer"
                target="_blank"
                href="https://www.migrainedisorders.org/"
              >
                Association of Migraine Disorders®
              </a>
            </li>
            <li>Others to come</li>
          </ul>
        </p>
        <p>
          <b>FUND OF CRYPTOART &ndash;</b> 10.22% of sale income
        </p>
        <p>
          <b>MARKETING &ndash;</b> 8% of sale income
        </p>
        <p>
          <b>SUPPORT FOR LAUNCH &ndash;</b> 4.66% of sale income
        </p>
        <p>
          <b>FUTURE DEVELOPMENT &ndash;</b> 11.12% of sale income
        </p>
        <p>
          <b>TEAM &ndash;</b> 44% of sale income
        </p>
        <p>
          <b>TOKEN TYPE &ndash;</b> ERC-721 &amp; SPL
        </p>
        <p>
          <b>ROYALTY FEES &ndash;</b> 10.22% (up to 99% goes to nonprofits)
        </p>
        <p>
          <b>NUMBER OF TRAITS &ndash;</b> 17
        </p>
        <p>
          <b>NUMBER OF ATTRIBUTES &ndash;</b> More than 300
        </p>
        <p>
          <b>ETH SMART CONTRACT &ndash;</b>{' '}
          0x11E22c865d99C1FfD89153cd1A5C9C33052A0c89
        </p>
        <p>
          <b>SOLANA SMART CONTRACT &ndash;</b> TBD
        </p>
      </>
    ),
  },
  {
    question: 'How does breeding work?',
    answer: (
      <>
        <p>
          Breeding of our NFTs is something we want to implement in 2022. Our
          project is focusing on real data statistics as well as on real
          pathologies and diseases that affect the society. We are therefore
          seeking a more scientific approach to characterise our digital
          collectibles. As a consequence, we try to base the NFTs on biological
          aspects as much as we can. This means that owners of a female and a
          male NFT will be able to breed.
        </p>

        <p>
          If you own a 22% NFT you will be able to adopt a child for a small
          commission that will fully be donated to orphanages.
        </p>
      </>
    ),
  },
  {
    question: 'How does the mint work?',
    answer: (
      <>
        <h3>On Ethereum</h3>
        <p>
          You can connect your{' '}
          <a
            href="https://metamask.io/download.html"
            rel="nofollow noopener noreferrer"
            target="_blank"
            className="link"
          >
            MetaMask
          </a>{' '}
          wallet (Chrome Plugin) to our site and mint from there. You can mint
          20 NFTs max per order. If you mint 10 NFTs you will get 1 for free!
        </p>
        <p>
          Before clicking the mint button, check that you have enough ETH in
          your wallet.
        </p>

        <h3>On Solana</h3>
        <p>Coming soon</p>
      </>
    ),
  },
  {
    question: 'How to enter the Whitelist?',
    answer: (
      <ol>
        <li>Reach level 7 on our Discord channel (No Spamming)</li>
        <li>Invite at least 5 people within our Discord channel</li>
        <li>
          Show great community engagement (suggest charity, like and comment
          tweets, etc...)
        </li>
      </ol>
    ),
  },
  {
    question: 'What are the benefits for holders?',
    answer: (
      <ol>
        <li>
          Members of the 22% community will be able to directly influence the
          charity process by voting among carefully selected associations;
        </li>
        <li>
          Holders of 2 NFTs (female and male) will be able to breed and obtain a
          kid;
        </li>
        <li>
          Holders of 2 NFTs of the same gender, will be able to adopt a kid for
          an amount that will be completely devolved to children’s homes;
        </li>
        <li>
          After the reach of multiple sales milestones, a considerable amount of
          collectibles will be airdropped to random holders;
        </li>
        <li>Some of the early holders will receive a print of their NFT;</li>
        <li>
          Members will have primary access to future developments, such as 3D
          models of their own assets in the Metaverse and the pre-sale of the
          project’s coin $22PC;
        </li>
        <li>
          Holders will be able to drive financial decisions regarding the
          digital art investment found of the 22% Team. Please refer to the next
          Chapter for a better understanding of the 22% investment found
        </li>
      </ol>
    ),
  },
  {
    question: 'Are there secondary sale royalties?',
    answer: (
      <p>
        22% is also the percentage of donation in the primary sale and 10.22%
        will be the royalties on the second market. The ultimate goal is to
        increase the amount of royalties we devolve as a contribution of 2.5%
        each quarter. This would enable us to donate 99% of all project incomes
        to verified charity funds by 2030.
      </p>
    ),
  },
  {
    question: 'What is the 22% vision?',
    answer: (
      <p>
        Our main goal is in fact to create awareness about the heterogeneity of
        the population and some of the most representative pathologies it is
        facing in the modern society. Because in the real world, as well as in
        the Metaverse, there will always be a downside related to the people’s
        health.
      </p>
    ),
  },
]

export default faqs
