import React from 'react'
import PropTypes from 'prop-types'
import './charity.scss'

const CharityMember = ({ el }) => (
  <a
    className="charity-el"
    href={el.link}
    rel="nofollow noopener noreferrer"
    target="_blank"
  >
    {el.image}
    <div className="content">
      <p className="name">{el.name}</p>
      <span className="text">{el.text}</span>
      {el.transactions.length && (
        <>
          <span className="transactions-title">Donations transactions:</span>
          <ul>
            {el.transactions.map((transaction) => (
              <li className="transaction-url">
                <a href={transaction} target="_blank" rel="noreferrer">
                  {transaction}
                </a>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  </a>
)

CharityMember.propTypes = {
  el: PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
    transactions: PropTypes.node.isRequired,
  }).isRequired,
}

const Charity = ({ charity }) => (
  <div className="charity row">
    {charity.map((el) => (
      <CharityMember key={el.id} el={el} />
    ))}
  </div>
)

Charity.propTypes = {
  charity: PropTypes.arrayOf(CharityMember.propTypes.el).isRequired,
}

export default Charity
