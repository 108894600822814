import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const charity = [
  {
    name: 'Cure Rare Disease',
    text: (
      <q>
        Cure Rare Disease™️ is developing custom therapeutics that are as unique
        to the individuals they are meant to treat. Our mission is to offer
        effective, life-saving treatments developed through collaborations with
        world-renowned researchers and clinicians, and in partnership with our
        generous donors. Our customized therapeutics are designed specifically
        for the men and women who continue to fight for their right to live
        long, full, healthy lives despite having been diagnosed with a rare
        genetic disorder for which they’ve been told there is no treatment or
        cure.
      </q>
    ),
    link: 'https://www.cureraredisease.org/',
    image: (
      <StaticImage
        className="image"
        src="../images/organisations/cure_rare_disease.jpg"
        alt="Cure Rare Disease"
        width={450}
        height={450}
        quality={100}
      />
    ),
    transactions: [
      'https://etherscan.io/tx/0x0b3bb78c7f7c7614c3e809c7f029f319c3bec07c51f23170364660b2c1521533',
    ],
  },
  {
    name: 'Association of Migraine Disorders®',
    text: (
      <q>
        The Association of Migraine Disorders® strives to expand the
        understanding of migraine by supporting research, education, and
        awareness.
      </q>
    ),
    link: 'https://www.migrainedisorders.org/',
    image: (
      <StaticImage
        className="image"
        src="../images/organisations/assoc_migraine_disorders.jpg"
        alt="Association of Migraine Disorders®"
        width={450}
        height={450}
        quality={100}
      />
    ),
    transactions: [
      'https://etherscan.io/tx/0x0b69b67c981f79f97b51c31fed8ec7dfa0e11d2d2d659c110972c0372c888826',
    ],
  },
  {
    // eslint-disable-next-line quotes
    name: "Children's Tumor Foundation",
    text: (
      <q>
        Our Mission: Drive research, expand knowledge, and advance care for the
        NF community. Our Vision: End NF.
      </q>
    ),
    link: 'https://www.ctf.org/',
    image: (
      <StaticImage
        className="image"
        src="../images/organisations/ctf.jpg"
        alt="Children\'s Tumor Foundation"
        width={450}
        height={450}
        quality={100}
      />
    ),
    transactions: [],
  },
]

export default charity
