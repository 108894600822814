import { useEffect } from 'react'
import isBrowser from '../utils'

const useOnTouchEnd = (ref, callback) => {
  useEffect(() => {
    if (isBrowser()) {
      ref.current.addEventListener('touchend', callback, { passive: true })
    }
    return () =>
      isBrowser() &&
      window.removeEventListener('touchend', callback, { passive: true })
  }, [ref, callback])
}

export default useOnTouchEnd
