import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './caroussel.scss'
import isBrowser from '../../utils'
import useOnWheel from '../../hooks/useOnWheel'
import useOnTouchMove from '../../hooks/useOnTouchMove'
import useOnTouchEnd from '../../hooks/useOnTouchEnd'

const Caroussel = ({ bgImagePath }) => {
  const carousselRef = useRef(null)
  const wheelTimeoutRef = useRef(null)
  const animationRef = useRef(null)
  const touchClientXRef = useRef(null)
  const mouseMoveSpeedRef = useRef(null)

  const [animationFallback, setAnimationFallback] = useState(false)

  const imageHeight = 450
  const imageWidth = isBrowser() ? window.innerWidth : 1920
  const carousselRatio = imageWidth / imageHeight

  useEffect(() => {
    if (!carousselRef.current.animate) {
      return setAnimationFallback(true)
    }
    animationRef.current = carousselRef.current.animate(
      [{ backgroundPositionX: '0' }, { backgroundPositionX: '-100000vw' }],
      {
        duration: 5000000 * carousselRatio,
        iterations: Infinity,
      },
    )

    animationRef.current.startTime -= 100000

    return null
  }, [carousselRef, carousselRatio])

  const handleTouchMove = (e) => {
    mouseMoveSpeedRef.current = null
    if (touchClientXRef.current) {
      animationRef.current.pause()
      const deltaX = e.touches[0].clientX - touchClientXRef.current
      animationRef.current.currentTime -= deltaX * 10
      mouseMoveSpeedRef.current = deltaX
    }

    touchClientXRef.current = e.touches[0].clientX
  }

  const handleTouchEndAnimation = () => {
    if (!mouseMoveSpeedRef.current) {
      return animationRef.current.play()
    }

    return requestAnimationFrame(() => {
      animationRef.current.currentTime -= mouseMoveSpeedRef.current * 10
      mouseMoveSpeedRef.current =
        Math.abs(Math.round(mouseMoveSpeedRef.current)) === 1
          ? null
          : mouseMoveSpeedRef.current * 0.97
      handleTouchEndAnimation()
    })
  }

  const handleTouchEnd = () => {
    touchClientXRef.current = null
    handleTouchEndAnimation()
  }

  const handleWheel = (e) => {
    animationRef.current.pause()
    animationRef.current.currentTime += e.deltaX * 10

    clearTimeout(wheelTimeoutRef.current)
    wheelTimeoutRef.current = setTimeout(() => {
      animationRef.current.play()
    }, 100)
  }

  useOnWheel(carousselRef, handleWheel)
  useOnTouchMove(carousselRef, handleTouchMove)
  useOnTouchEnd(carousselRef, handleTouchEnd)

  return (
    <div
      className={classNames('caroussel', {
        'caroussel--animated-fallback': animationFallback,
      })}
      ref={carousselRef}
      style={{
        background: `url(${bgImagePath}) 0 0/auto 100% repeat`,
        height: imageHeight,
      }}
    />
  )
}

Caroussel.propTypes = {
  bgImagePath: PropTypes.string.isRequired,
}

export default Caroussel
