import React from 'react'
import BgBlock from '../components/bg-block'
import Caroussel from '../components/caroussel/caroussel'
import Charity from '../components/charity/charity'
import Faqs from '../components/faq/faq'
import ImageParallax from '../components/image-parallax/ImageParallax'
import Layout from '../components/layout/layout'
import Roadmap from '../components/roadmap/Roadmap'
import Team from '../components/team/team'
import faqs from '../content/faqs'
import cards from '../content/roadmap'
import team from '../content/team'
import charity from '../content/charity'
import BgImage from '../images/caroussel.webp'
import '../styles/main.scss'

// markup
const IndexPage = () => (
  <Layout
    isHomePage
    metaTitle="22 percent"
    metaDescription="The first multi-chain NFT Project launched in 2022. A collection of 10,000 unique digital collectibles distributed on Ethereum and Solana that aim at creating awareness about the biological heterogeneity of our society and support charities through transparent donations. Enter a community that drives healthcare-related aids and be the first to benefit from future developments and airdrops."
  >
    <ImageParallax>
      <h1>Welcome to 22% !</h1>
      <p>
        The first multi-chain NFT Project launched in 2022. A collection of
        10,000 unique digital collectibles distributed on Ethereum and Solana
        that aim at creating awareness about the biological heterogeneity of our
        society and support charities through transparent donations.
      </p>
      <p>
        Enter a community that drives healthcare-related aids and be the first
        to benefit from future developments and airdrops.
      </p>
    </ImageParallax>
    <Caroussel bgImagePath={BgImage} />
    <BgBlock
      color="var(--bg-block-color-4)"
      backgroundColor="var(--bg-block-bg-color-4)"
    >
      <h2>Milestones</h2>
      <Roadmap cards={cards} />
    </BgBlock>
    <BgBlock color="black" backgroundColor="#E7DCBD" id="team">
      <h2>Team</h2>
      <Team team={team} />
    </BgBlock>
    <BgBlock
      color="var(--bg-block-color-2)"
      backgroundColor="var(--bg-block-bg-color-2)"
    >
      <h2>Selected Organisations</h2>
      <Charity charity={charity} />
    </BgBlock>
    <BgBlock
      color="var(--bg-block-color-3)"
      backgroundColor="var(--bg-block-bg-color-3)"
    >
      <h2>FAQ</h2>
      <Faqs faqs={faqs} />
    </BgBlock>
  </Layout>
)

export default IndexPage
