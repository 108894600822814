/* eslint-disable no-undef */
import classNames from 'classnames'
import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import useOnScroll from '../../hooks/useOnScroll'
import './image-parallax.scss'
import TTPercent from '../../assets/logo.svg'
import { msToTime } from '../../utils'

const GO_LIVE_DATE = new Date('April 3, 2022 17:00:00')
GO_LIVE_DATE.setUTCHours(17)

const isBrowser = () => typeof window !== 'undefined'

const ImageParallax = ({ children }) => {
  const [fixPositionM, setFixPositionM] = useState(false)
  const [fixPositionD, setFixPositionD] = useState(false)
  const [countdown, setCountdown] = useState('--:--:--')
  const [countdownRunning, setCountdownRunning] = useState(false)
  const textRefM = useRef(null)
  const textRefD = useRef(null)
  const imgRef = useRef(null)

  const calcTimer = () => {
    const ms = GO_LIVE_DATE - new Date()
    if (ms <= 0) {
      setCountdownRunning(false)
    } else {
      setCountdown(msToTime(ms))
    }
  }

  useEffect(() => {
    calcTimer()

    const interval = setInterval(() => {
      calcTimer()
      if (!countdownRunning) {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const paddingDesktop = Number(
    isBrowser()
      ? getComputedStyle(document.documentElement)
          .getPropertyValue('--padding-desktop')
          .split('px')[0]
      : 0,
  )
  const paddingMobile = Number(
    isBrowser()
      ? getComputedStyle(document.documentElement)
          .getPropertyValue('--padding-mobile')
          .split('px')[0]
      : 0,
  )

  const calculateImagePosition = () => {
    const textBCRM = textRefM.current.getBoundingClientRect()
    const textBCRD = textRefD.current.getBoundingClientRect()

    const textTopM = textBCRM.top - paddingMobile
    const textBottomD = textBCRD.top - paddingDesktop

    if (window.innerHeight - (window.innerHeight * 0.5 - 260) < textBottomD) {
      setFixPositionD(false)
    } else {
      setFixPositionD(true)
    }

    if (window.innerHeight - 32 < textTopM) {
      setFixPositionM(false)
    } else {
      setFixPositionM(true)
    }
  }

  useOnScroll(calculateImagePosition)
  useEffect(() => {
    calculateImagePosition()
  }, [])

  return (
    <>
      <div className="image-parallax">
        <div className="bottom">
          <div className="title">
            <TTPercent alt="logo" className="parallax-logo" />
            <br />
            <h2>An NFT Project that saves lives</h2>
            <div className="btn-group">
              {countdownRunning ? (
                <div
                  style={{
                    backgroundColor: 'rgb(199,71,69)',
                    color: 'white',
                    padding: 16,
                    borderRadius: 64,
                    fontSize: 32,
                    textAlign: 'center',
                    maxWidth: 720,
                    boxSizing: 'border-box',
                  }}
                >
                  {countdown} to the drop
                </div>
              ) : (
                <>
                  <Link to="/mint" className="button">
                    Mint on Ethereum
                  </Link>
                  <Link to="/mint-solana" className="button">
                    Mint on Solana
                  </Link>
                  <button
                    type="button"
                    className="button"
                    onClick={() => {
                      document
                        .querySelector('#creatify_script')
                        .insertAdjacentElement(
                          'afterend',
                          creatify_inject_element,
                        )
                      show_creatify_popup()
                    }}
                  >
                    Buy with Credit Card on Ethereum
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'image',
            fixPositionM && 'image--visible-m',
            fixPositionD && 'image--visible-d',
          )}
          ref={imgRef}
        >
          <StaticImage
            className="image-base"
            src="../../../static/image-base.png"
            alt="ntf"
            width={650}
            height={650}
            quality={100}
            loading="eager"
            placeholder="none"
          />
          <StaticImage
            className="image-overlay"
            src="../../../static/image-overlay.png"
            alt="ntf"
            width={650}
            height={650}
            quality={100}
            loading="eager"
            placeholder="none"
          />
        </div>
        <div className="under">
          <div className="text" ref={textRefM}>
            {children}
          </div>
        </div>
      </div>
      <div ref={textRefD} />
    </>
  )
}

ImageParallax.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ImageParallax
