import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import useOnScreen from '../../hooks/useOnScreen'

import './roadmap.scss'

const Card = ({ i, card }) => {
  const [visible, setVisible] = useState(false)
  const cardRef = useRef(null)
  const isVisible = useOnScreen(cardRef)

  useEffect(() => {
    if (isVisible) {
      setVisible(isVisible)
    }
  }, [isVisible])

  return (
    <div
      ref={cardRef}
      className={classNames(
        'card',
        visible && 'card--visible',
        i % 2 && 'card--invisible',
      )}
    >
      <h3 className={classNames(card.isQuartal && 'quartal')}>{card.title}</h3>
      {card.text}
      <div className="dot" />
    </div>
  )
}

Card.propTypes = {
  i: PropTypes.number.isRequired,
  card: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired,
    isQuartal: PropTypes.bool.isRequired,
  }).isRequired,
}

const Roadmap = ({ cards }) => (
  <div className="timeline">
    <div className="container">
      <div className="cards cards--left">
        {cards.map((card, i) => (
          <Card key={card.title} i={i} card={card} />
        ))}
      </div>

      <div className="lines" />

      <div className="cards cards--right">
        {cards.map((card, i) => (
          <Card key={card.title} i={i + 1} card={card} />
        ))}
      </div>
    </div>
  </div>
)

Roadmap.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      i: PropTypes.number,
      card: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
  ).isRequired,
}

export default Roadmap
