import { useEffect, useMemo, useState } from 'react'
import isBrowser from '../utils'

const loadPolyfill = () => import('intersection-observer')

const useOnScreen = (ref) => {
  const [intersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => {
    if (isBrowser()) {
      if (!IntersectionObserver) {
        loadPolyfill().then(() => {
          return new IntersectionObserver(([entry]) =>
            setIntersecting(entry.isIntersecting),
          )
        })
      } else {
        return new IntersectionObserver(([entry]) =>
          setIntersecting(entry.isIntersecting),
        )
      }
    }

    return null
  }, [])

  const memoizedRef = useMemo(() => ref, [ref])

  useEffect(() => {
    if (isBrowser()) {
      observer.observe(memoizedRef.current)
    }
    return () => isBrowser() && observer.disconnect
  }, [observer, memoizedRef])

  return intersecting
}

export default useOnScreen
