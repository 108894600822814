import { useEffect } from 'react'
import isBrowser from '../utils'

const useOnTouchMove = (ref, callback) => {
  useEffect(() => {
    if (isBrowser()) {
      ref.current.addEventListener('touchmove', callback, { passive: true })
    }
    return () =>
      isBrowser() &&
      window.removeEventListener('touchmove', callback, { passive: true })
  }, [ref, callback])
}

export default useOnTouchMove
